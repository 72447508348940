.contentCenter {
  margin-left: auto;
  margin-right: auto;
}
.textAlignCenter {
  text-align: center;
}

/* 各追加・編集画面で使用するカードのスタイル */
.cardStyleInDetail {
  width: 600px;
  height: 700px;
  text-align: center;
  border: 1px solid #a3a3a3;
  margin: 55px;
  padding: 30px;
}
/* 各追加・編集画面で使用するカードのスタイル */
.userCardStyle {
  width: 550px;
  height: 450px;
  text-align: center;
  border: 1px solid #a3a3a3;
  margin: 55px;
}

.formLabel {
  color: #2699fb;
  font-size: 18px;
  font-weight: 500;
}
.rowStyle {
  margin-top: 12px;
}
.colStyle {
  left: 40px;
  text-align: left;
  transform: translateY(8%);
}

.searchResultPdfTitle {
  font-size: 1rem;
}

.searchResultEachPage {
  margin-left: 3rem;
  padding-right: 1rem;
}

.textHighlight {
  background-color: yellow;
}

.searchAlignment {
  display: flex;
  align-items: center;
}

.pdfReadingAlignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
