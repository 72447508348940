/* テーブルに関する背景色 */
.tableRowLight {
  background-color: #ffffff;
}
.tableRowLight:hover {
  background-color: #ffffff;
}
.tableRowLight > td {
  background-color: #ffffff !important;
}
.tableRowLight > td:hover {
  background-color: #ffffff !important;
}
.tableRowDark {
  background-color: #dfdfdf;
}
.tableRowDark:hover {
  background-color: #dfdfdf;
}
.tableRowDark > td {
  background-color: #dfdfdf !important;
}
.tableRowDark > td:hover {
  background-color: #dfdfdf !important;
}
