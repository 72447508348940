/* 類義語一覧画面でのみ使用 */
.cardStyleInList {
  height: 300px;
  width: 300px;
  margin: 6px;
  overflow: auto;
  box-shadow: 15px 20px 45px 0 rgb(115 213 243);
}
.cardStyleInList:hover {
  background-color: rgb(246, 246, 246);
}
.cardStyleInList .ant-card-head-wrapper {
  display: inline-flex;
}
